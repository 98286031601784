import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {createUser} from "../../utils/api"
import { Save } from "@mui/icons-material";
import { Divider } from "@mui/material";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  minWidth: 544,
  p: 4,
  borderRadius: '16px'
};

export default function DetailModal({ openModal, closeModal,  sendData, name }) {
  const [data, setData] = React.useState({
    firstName: '', lastName: '', email: '', relationShip: 'Owner'
  });
  const [errorText, setErrorText] = React.useState({
    lastName: '', firstName: '', email: '', relationShip: ''
  });

  const handleChange = (e) => {
    setErrorText({
      ...errorText,
      [`${e.target.name}`]: ''
    });
    setData({
      ...data,
      [`${e.target.name}`]: e.target.value
    })
  };

  const save = async () => {
    if(data.firstName == '') {
      setErrorText({
        ...errorText,
        firstName: '* First Name is required.'
      })
      return false;
    }
    if(data.lastName == '') {
      setErrorText({
        ...errorText,
        lastName: '* Last Name is required.'
      })
      return false;
    }
    if(data.email == '') {
      setErrorText({
        ...errorText,
        email: '* Email is required.'
      })
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      setErrorText({
        ...errorText,
        email: '* Invalid email address.'
      });
      return false;
    }
    sendData(data);
    // let res = await  createUser(data);
    // if(res.success) {
    //   successSaved();
    // }
  }
  
  return (
    <div>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <span style={{ position: "absolute", top: -30 }}>X</span> */}
        <Box sx={style}>
          <Typography id="modal-modal-title" textAlign="center" mt={3} sx={{ fontSize: '16px', fontWeight: 700 }}>
            Send PDF
          </Typography>
          <div style={{ textAlign: 'center', marginTop: 16 }}>
            <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.99998 0H24.2412L35.4999 11.215V37.5C35.4999 38.8812 34.38 40 32.9999 40H2.99998C1.61994 40 0.5 38.8812 0.5 37.5V2.49998C0.5 1.11877 1.62007 0 2.99998 0Z" fill="#E2574C"/><path d="M35.4637 11.25H26.75C25.3699 11.25 24.25 10.13 24.25 8.74998V0.0249634L35.4637 11.25Z" fill="#B53629"/><path d="M26.1227 18.9537C26.5415 18.9537 26.7465 18.5887 26.7465 18.235C26.7465 17.8687 26.5327 17.515 26.1227 17.515H23.7377C23.2714 17.515 23.0115 17.9012 23.0115 18.3275V24.1887C23.0115 24.7112 23.3089 25.0012 23.7114 25.0012C24.1115 25.0012 24.4103 24.7112 24.4103 24.1887V22.58H25.8528C26.3003 22.58 26.524 22.2137 26.524 21.85C26.524 21.4938 26.3003 21.1399 25.8528 21.1399H24.4103V18.9537H26.1227ZM18.0614 17.515H16.3164C15.8426 17.515 15.5064 17.84 15.5064 18.3224V24.1937C15.5064 24.7924 15.9901 24.98 16.3363 24.98H18.1676C20.3351 24.98 21.7663 23.5537 21.7663 21.3524C21.7652 19.025 20.4177 17.515 18.0614 17.515ZM18.1452 23.5325H17.0814V18.9625H18.0402C19.4915 18.9625 20.1227 19.9363 20.1227 21.28C20.1227 22.5375 19.5027 23.5325 18.1452 23.5325ZM11.7527 17.515H10.024C9.53521 17.515 9.2627 17.8374 9.2627 18.3275V24.1887C9.2627 24.7112 9.57518 25.0012 9.99514 25.0012C10.4151 25.0012 10.7276 24.7112 10.7276 24.1887V22.4774H11.8113C13.1488 22.4774 14.2526 21.5299 14.2526 20.0061C14.2527 18.515 13.1877 17.515 11.7527 17.515ZM11.724 21.1025H10.7277V18.8912H11.724C12.339 18.8912 12.7302 19.3712 12.7302 19.9975C12.729 20.6226 12.339 21.1025 11.724 21.1025Z" fill="white"/></svg>
            <p style={{ fontSize: '16px', fontWeight: 700 }}>{name}</p>
          </div>
         
          <Grid sx={{ mt: 3, position: "relative" }}>
            <MDBox mb={2}>
              <p style={{ fontSize: 16, fontWeight: 700 }}>First Name</p>
              <input style={{ border: 'none', background: '#F2F5FA', height: '40px', width: '100%', padding: 10, outline: 'none', marginTop: '6px' }} type="text" placeholder="Enter first name"  name='firstName' value={data?.firstName} onChange={handleChange}/>
              <Typography sx={{color: 'red', fontSize:  14, mt: 1, ml: 1}}>{errorText.firstName}</Typography>
            </MDBox>
            <MDBox mb={2}>
              <p style={{ fontSize: 16, fontWeight: 700 }}>Last Name</p>
              <input type="text" placeholder="Enter last name" style={{ border: 'none', background: '#F2F5FA', height: '40px', width: '100%', padding: 10, outline: 'none', marginTop: '6px' }} name='lastName' value={data?.lastName} onChange={handleChange}/>
              <Typography sx={{color: 'red', fontSize:  14, mt: 1, ml: 1}}>{errorText.lastName}</Typography>
            </MDBox>
            <MDBox mb={2}>
              <p style={{ fontSize: 16, fontWeight: 700 }}>Email</p>
              <input type="text" placeholder="Enter email" style={{ border: 'none', background: '#F2F5FA', height: '40px', width: '100%', padding: 10, outline: 'none', marginTop: '6px' }}  name='email' onChange={handleChange} value={data?.email}/>
              <Typography sx={{color: 'red', fontSize:  14, mt: 1, ml: 1}}>{errorText.email}</Typography>
            </MDBox>
          </Grid>
          <div style={{ width: '100%', borderTop: '1px solid #D9D9D9', height: 1, marginTop: '32px' }} />
          <Grid sx={{mt: 4, display: 'flex', justifyContent: 'center'}}>
            <button  mt={2} onClick={() => closeModal()} color="white" style={{ border: '2px solid #04547C', color: '#04547C', borderRadius: 2, width: 160, height: 40, cursor: 'pointer' }}>
              Cancel
            </button>
            <button  mt={2} onClick={() => save()} color="#04547C" style={{ marginLeft: 16, border: '2px solid #04547C', color: '#FFFFFF', background: '#04547C', borderRadius: 2, width: 160, height: 40, cursor: 'pointer' }}>
              Send
            </button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
