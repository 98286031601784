import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = process.env.REACT_APP_DOMAIN;
// axiosInstance.defaults.baseURL = "http://127.0.0.1:8000"+'/api';
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;
