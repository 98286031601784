// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
import Grader from "layouts/grader";
import GoogleGrader from "layouts/googlegrader";
import GraderExternal from "layouts/grader/external";
import GraderForGoogle from "layouts/googlegrader/GraderForGoogle";
import Index from "layouts/index";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "index",
    key: "index",
    icon: <Icon fontSize="small">Index</Icon>,
    route: "/index",
    component: <Index />,
  },
  {
    type: "collapse",
    name: "grader",
    key: "grader",
    icon: <Icon fontSize="small">Grader</Icon>,
    route: "/grader",
    component: <GoogleGrader />,
  },
  {
    type: "collapse",
    name: "grader",
    key: "grader",
    icon: <Icon fontSize="small">Grader</Icon>,
    route: "/grader/:id",
    component: <Grader />,
  },
  {
    type: "collapse",
    name: "graderExternal",
    key: "graderExternal",
    icon: <Icon fontSize="small">Grader External</Icon>,
    route: "/grader-external/:id",
    component: <GraderExternal />,
  },
  {
    type: "collapse",
    name: "googleGrader",
    key: "googleGrader",
    icon: <Icon fontSize="small">Google Grader</Icon>,
    route: "/google-grader/:account_id/:location_id",
    component: <GraderForGoogle />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
