import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  minWidth: 400,
  borderRadius: '8px'
};

export default function AccountSelectModal({ openModal, closeModal,  accounts, sendData }) {
  const [accountId, setAccountId] = React.useState(null);
  const [displayData, setDisplayData] = React.useState([]);
  const save = async () => {
    sendData(accountId);
  }
  const selectAccount = (e) => {
    setAccountId(e.target.value);
  }
  React.useEffect(() => {
    setAccountId(null);
  }, [openModal]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  React.useEffect(() => {
    if (accounts.length > 0) {
      let cn =  parseInt(accounts.length / 20);
      let rm = accounts.length % 20;
      let cc = 0;
      if (rm > 0) {
        cc = cn + 1; 
      } else {
        cc = cn;
      }
      setCount(cc);
    }
  }, [accounts])
  const handleChange = (event, value) => {
    setPage(value);
  };
  const getDisplayData = () => {
    const start = (page - 1) * 20;
    const end = start + 20;
    const disData = accounts.slice(start, end);
    setDisplayData(disData);
  }
  React.useEffect(() => {
    if (page > 0) {
      getDisplayData();
    }
  }, [page, accounts])
  console.log(page);
  return (
    <div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <span style={{ position: "absolute", top: -30 }}>X</span> */}
        <Box sx={style}>
          <Grid sx={{background: '#C9ECF3', p: 3, borderRadius: '8px 8px 0 0'}}>
            <Typography sx={{color: '#1B1B42 !important', fontSize: '20px !important', fontWeight: 500}} id="modal-modal-title" textAlign="center">
              Account
            </Typography>
          </Grid>
          <Grid sx={{ mt: 3, position: "relative", px: 2, maxHeight: 500, overflowY: accounts?.length > 10 ? 'scroll' : 'auto'  }}>
            <Typography sx={{color: '#5C5C5C !important', fontSize: '16px', fontWeight: 400}} textAlign="center">Select an account:</Typography>
            <RadioGroup
              sx={{ mt: 1}}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {
                displayData.map((account) => {
                  return (
                    <FormControlLabel
                      value={account.name}
                      control={<Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            border: '0.0625rem solid #04547C'
                          }
                        }}
                      />}
                      label={account.accountName}
                      onChange={(e) => selectAccount(e)}
                      className={accountId == account?.name ? 'activeLabel' : ''}
                    />
                  )
                })
              }
            </RadioGroup>
          </Grid>
          {count > 1 && (
            <Grid sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination count={count} onChange={handleChange} />
            </Grid>
          )}
          <Grid
            sx={{ 
              px: 4,
              py: 3,
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTop: '1px solid #D9D9D9'
            }}
          >
            <button
              className="cancelButton"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button
              className="saveButton"
              onClick={() => save()}
              disabled={accountId ? false : true}
            >
              Choose Account
            </button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
