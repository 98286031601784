import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {createUser} from "../../utils/api"
import { Save } from "@mui/icons-material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  minWidth: 400,
  borderRadius: '8px'
};

export default function LocationSelectModal({ openModal, closeModal,  locations, sendData }) {
  const [errorText, setErrorText] = React.useState({
    lastName: '', firstName: '', email: '', relationShip: ''
  });
  const [locationId, setLocationId] = React.useState(null);
  const save = async () => {
    sendData(locationId);
  }
  const selectAccount = (e) => {
    setLocationId(e.target.value);
  }
  React.useEffect(() => {
    setLocationId(null);
  }, [openModal]);
  return (
    <div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* <span style={{ position: "absolute", top: -30 }}>X</span> */}
        <Box sx={style}>
        <Grid sx={{background: '#C9ECF3', p: 3, borderRadius: '8px 8px 0 0'}}>
            <Typography sx={{color: '#1B1B42 !important', fontSize: '20px !important', fontWeight: 500}} id="modal-modal-title" textAlign="center">
              Location
            </Typography>
            {/* <Typography sx={{color: 'white !important', fontSize: '14px', fontWeight: 300}} textAlign="center">Select the location you would like to grade.</Typography> */}
          </Grid>
          {locations?.length > 0 ? (
            <Grid sx={{ mt: 3, position: "relative", px: 2, maxHeight: 500, overflowY: locations?.length > 10 ? 'scroll' : 'auto' }}>
              <Typography sx={{color: '#5C5C5C !important', fontSize: '16px', fontWeight: 400}} textAlign="center">Select the location you would like to grade:</Typography>
              <RadioGroup
                  sx={{ mt: 1}}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {
                  locations.map((location) => {
                    return (
                    <FormControlLabel
                      value={location.name}
                      control={<Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            border: '0.0625rem solid #04547C'
                          }
                        }}
                      />}
                      label={location.title}
                      onChange={(e) => selectAccount(e)}
                      className={locationId == location?.name ? 'activeLabel' : ''}
                    />
                    )
                  })
                }
              </RadioGroup>
            </Grid>
          ) : (
            <Grid sx={{ mt: 3, position: "relative", px: 2 }}>
              <Typography>Sorry, there does not appear to be a location associated with your Google account.</Typography>
            </Grid>
          )}
          <Grid
            sx={{ 
              px: 4,
              py: 3,
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderTop: '1px solid #D9D9D9'
            }}
          >
            <button
              className="cancelButton"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button
              className="saveButton"
              onClick={() => save()}
              disabled={locationId ? false : true}
            >
              Choose Account
            </button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
