import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { CircularProgress, Backdrop, Typography, Button } from '@mui/material';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import logoImage from "assets/images/google_business_icon.svg";
import {getGoogleAccounts, getGoogelLocations} from "../../utils/api";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useGoogleLogin } from '@react-oauth/google';
import AccountSelectModal from "components/Modal/AccountSelectModal";
import LocationSelectModal from "components/Modal/LocationSelectModal";
import { useNavigate } from "react-router-dom";

function Googlegrader() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [googelToken, setGoogleToken] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [locationId, setLocationId] = useState(null);

  const getAccounts = async (pageToken=null, tempArray=[]) => {
    let res = await (getGoogleAccounts(googelToken, pageToken));
    if (res?.accounts) {
      let temArrayMerge =  tempArray.concat(res?.accounts);
      if (res?.nextPageToken) {
        await getAccounts(res?.nextPageToken, temArrayMerge);
      } else {
        setAccounts(temArrayMerge);
        return temArrayMerge;
      }
    } else {
      return [];
    }
  }
  const getAccountList = async () => {
    setLoading(true)
    await getAccounts();
    setLoading(false);
    setOpenAccountModal(true);
  }
  useEffect(() => {
    if (googelToken) {
      getAccountList();
    }
  }, [googelToken]);
  const getGoogleAuth = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // let token = "ya29.a0AfB_byCF0Q-jKD_QHdc6ksP-jIkSeL-fgHAoLLRnn9X7Hs-9Rx3mmJH28GmKdapXZsufR45CTLWDsdx83WUFOowqqNYLkpbnN0dwmm6hALM3wtgBJ8MWiGUhnmMdc-2hpF_a2N-thbjBp_RDgyFk-0u3joRgH3xtsgvdpGcaCgYKAX4SARMSFQGOcNnCwpgJSD65JtGVIZzMQ1Z5jg0174";
      setGoogleToken(tokenResponse.access_token);
      // setGoogleToken(token);
      localStorage.setItem('google_auth_token', tokenResponse.access_token);
    },
    onError: (error) => {
      console.log(error);
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/business.manage'
  });
  const closeModal = () => {
    setOpenAccountModal(false);
    localStorage.removeItem('google_auth_token');
  }
  const getLocations = async (aId) => {
    setLoading(true);
    setAccountId(aId);
    let res = await getGoogelLocations(aId, googelToken);
    if (res?.locations && res?.locations.length > 0) {
      setLocations(res?.locations);

    } else {
      setLocations([]);
    }
    setLoading(false);
    setOpenLocationModal(true);
  }
  const generateGrader = (lId) => {
    setLocationId(lId);
    window.open(`/google-grader/${accountId.replace('accounts/', '')}/${lId.replace('locations/', '')}`, '_blank');
  }
  return (
    <DashboardLayout>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <Grid sx={{ display: 'flex', justifyContent: 'center', minHeight: '95vh', alignItems: 'center' }} pt={6} pb={3}>
        <Grid container spacing={6} sx={{ maxWidth: '640px' }} >
          <Grid item xs={12} lg={12} py={4} >
            <Card sx={{ borderRadius: '16px', height: '460px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
              <Grid sx={{ borderRadius: 0, textAlign: 'center', width: '100%', p: 6 }}>
                <img src={logoImage}  style={{ borderRadius: '50%', width: '80px' }}/>
                <Typography sx={{ color: '#1B1B42', fontSize: '20px', fontWeight: 900, mt: 2 }}>Grade your Google Business Profile</Typography>
                <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={{ color: '#5C5C5C', fontSize: '16px', mt: 2, fontWeight: 700, width: '90%' }}>To get your report, just log in with Google Your Business account on the next page and click "allow".</Typography>
                </Grid>
                <Grid sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 1 }}>
                  <button className="grader-button" onClick={() => getGoogleAuth()}>
                    Start Grading
                  </button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/* <Footer /> */}
      <AccountSelectModal 
        openModal={openAccountModal}
        closeModal={() => closeModal()}
        accounts={accounts}
        sendData={(data) => {getLocations(data)}}
      />
      <LocationSelectModal 
        openModal={openLocationModal}
        closeModal={() => setOpenLocationModal(false)}
        locations={locations}
        sendData={(data) => {generateGrader(data)}}
      />
    </DashboardLayout>
  );
}

export default Googlegrader;
