import { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { CircularProgress, Backdrop, Typography } from '@mui/material';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDSnackbar from "components/MDSnackbar";
import { useNavigate } from "react-router-dom";
function Index() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [placeId, setPlaceId] = useState(null);
  const [errorSB, setErrorSB] = useState(false);
  const inputRef = useRef(null);
  const closeErrorSB = () => setErrorSB(false);
  const openErrorSB = () => setErrorSB(true);
  useEffect(() => {
    const autoComplete = new window.google.maps.places.Autocomplete(
      inputRef.current
    );
    autoComplete.addListener('place_changed', () => {
      const place = autoComplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        alert("This location is not available");
      }
      if (place.geometry.viewport || place.geometry.location) {
        console.log(place);
        setPlaceId(place.place_id);
      }
    });
  }, []);

  const processAnalytics = async () => {
    console.log(placeId);
    if (!placeId) {
      openErrorSB();
    }
    navigate(`/grader-external/${placeId}`);
  };
  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Empty Business Name"
      content="You must select the business name."
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  return (
    <DashboardLayout>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{ display: 'flex', justifyContent: 'center' }} pt={6} pb={3}>
        <Grid container spacing={6} sx={{ maxWidth: '1240px' }} >
          <Grid item xs={12} lg={12} py={4} sx={{ textAlign: 'center', mt: 8 }} >
            <img width="360px" src="https://spp-clients.s3-accelerate.amazonaws.com/b091fc94-851f-47a7-a556-730ef2c4daf7/cropped-gmb-gorilla-logo-(1).png" alt="logo" />
            <Typography sx={{ fontSize: '46px' }}>Is your GMB listing fully optimized?</Typography>
            <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '120px', borderBottom: '2px solid #ff6900', marginTop: '24px', textAlign: 'center' }} />
            </Grid>
            <Typography sx={{ mt: 2 }} >Search for your business name below as it appears on Google maps and analyze.</Typography>
            <div className="inner-form">
              <div className="input-field second-wrap">
                <input
                  ref={inputRef}
                  id="search"
                  type="text"
                  aria-invalid="false"
                  placeholder="Your Business Name"
                  className="pac-target-input"
                  autoComplete="off"
                />
              </div>
              <div className="input-field third-wrap">
                <button 
                  className="btn-search"
                  type="button"
                  id="submit"
                  disabled={!placeId}
                  onClick={processAnalytics}
                >
                  Analyze
                </button>
              </div>
            </div>
            {renderErrorSB}
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Index;
