/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import { useParams } from 'react-router-dom';
import DetailModal from "components/Modal/detail";
import JsPDF from 'jspdf';
import { CircularProgress, Backdrop } from '@mui/material';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {getLocationDataById, sendDataToUsers} from "../../utils/api";

function GraderExternal() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const {id} = useParams();
  const [location, setLocation] = useState('');
  const getData = async () => {
    setLoading(true);
    let res = await getLocationDataById({ id });
    setData(res?.data);
    setLocation(res?.data?.location);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => {
    const input = document.getElementById('pdf_form');
    const report = new JsPDF({
      orientation: "p",
      unit: "pt",
      format: [1264, 1600]
    });
    report.html(document.querySelector('#pdf_form'),{margin: [40, 40, 40, 40]}).then(() => {
      report.save(`grader.pdf`);
    });
    setInfoSB(true);
  };
  const generatePDFToUsers = async (data) => {
    setLoading(true);
    const report = new JsPDF({
        orientation: "p",
        unit: "pt",
        format: [1264, 1600]
    });
    await report.html(document.querySelector('#pdf_form'),{margin: [40, 40, 40, 40]}).then(() => {
        const base64String = report.output('datauristring');
        sendDataToUsers({...data, pdf: base64String, location });
    });
    setOpenDetailModal(false);
    setLoading(false);
    setInfoSB(true);
    // console.log(data);
  };
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [totalScore, setTotalScore] = useState(0);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const closeModal = () => {
    setOpenDetailModal(false);
  }
  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      A simple {name} alert with{" "}
      <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
        an example link
      </MDTypography>
      . Give it a click if you like.
    </MDTypography>
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title="GMB Gorilla"
      content="PDF was generated successfully."
      // dateTime="11 mins ago"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  console.log(data);

  const changeValue = (e, dic) => {
    let temp = {...data};
    let temp_dic = temp[`${dic}`];
    temp_dic[`${e.target.name}`] = e.target.value;
    temp[`${dic}`] = temp_dic;
    setData(temp);
  };

  const sumObjectValues = (obj) => {
    let sum = 0;
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        sum += sumObjectValues(obj[key]);
      } else {
        if (key !== 'total') {
          sum += parseInt(obj[key]) ? parseInt(obj[key]) : 0;
        }
      }
    }
    return sum;
  };
  useEffect(() => {
    if (data) {
      let sum = sumObjectValues(data);
      setTotalScore(sum);
    }
  }, [data])
  const sendPDFToUsers = () => {
    setOpenDetailModal(true);
  }
  return (
    <DashboardLayout>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{ display: 'flex', justifyContent: 'center' }} pt={6} pb={3}>
        <Grid container spacing={6} sx={{ maxWidth: '1240px' }} >
          <Grid item xs={12} lg={12} py={4} >
            <Card>
              <div id="pdf_form">
                <MDBox p={4} sx={{ textAlign: 'center', pt: 6 }}>
                  <MDTypography variant="h3">GMB Grader Score</MDTypography>
                  <MDTypography sx={{ mt: 3 }} variant="h1">{totalScore}</MDTypography>
                </MDBox>
                <Grid container py={2} px={2}>
                  <Grid item lg={6} >
                    <MDBox>
                      <MDTypography variant="h5">Business Information</MDTypography>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Business name</MDTypography>
                          <MDInput name="name" value={data?.business?.name} onChange={(e) => changeValue(e, 'business')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Business address</MDTypography>
                          <MDInput name="address" value={data?.business?.address} onChange={(e) => changeValue(e, 'business')} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Business hours</MDTypography>
                          <MDInput name="hours" value={data?.business?.hours} onChange={(e) => changeValue(e, 'business')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Business website</MDTypography>
                          <MDInput name="website" value={data?.business?.website} onChange={(e) => changeValue(e, 'business')} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Business est date</MDTypography>
                          <MDInput name="est_date" value={data?.business?.est_date} onChange={(e) => changeValue(e, 'business')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Business description</MDTypography>
                          <MDInput name="description" value={data?.business?.description} onChange={(e) => changeValue(e, 'business')} fullWidth />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                  <Grid item lg={6} >
                    <MDBox>
                      <MDTypography variant="h5">Design</MDTypography>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Profile avatar</MDTypography>
                          <MDInput name="avatar" value={data?.media?.avatar} onChange={(e) => changeValue(e, 'media')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Profile cover</MDTypography>
                          <MDInput name="cover" value={data?.media?.cover} onChange={(e) => changeValue(e, 'media')} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Exterior photos </MDTypography>
                          <MDInput name="exterior" value={data?.media?.exterior} onChange={(e) => changeValue(e, 'media')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Product/service photos</MDTypography>
                          <MDInput name="product" value={data?.media?.product} onChange={(e) => changeValue(e, 'media')} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Interior photos</MDTypography>
                          <MDInput name="interior" value={data?.media?.interior} onChange={(e) => changeValue(e, 'media')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Team photos</MDTypography>
                          <MDInput name="team" value={data?.media?.team} onChange={(e) => changeValue(e, 'media')} fullWidth />
                        </MDBox>
                      </Grid>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Videos</MDTypography>
                          <MDInput name="video" value={data?.media?.video} onChange={(e) => changeValue(e, 'media')} fullWidth />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                  <Grid item lg={12} >
                    <MDBox>
                      <MDTypography variant="h5">Categorization</MDTypography>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Primary category accurate for keyword</MDTypography>
                          <MDInput name="primary" value={data?.category?.primary} onChange={(e) => changeValue(e, 'category')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Secondary categories accurate for keywords</MDTypography>
                          <MDInput name="secondary" value={data?.category?.secondary} onChange={(e) => changeValue(e, 'category')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Service areas set-up based on targeting</MDTypography>
                          <MDInput name="service_area" value={data?.category?.service_area} onChange={(e) => changeValue(e, 'category')} fullWidth />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                  <Grid item lg={12} sx={{ mt: 4 }} >
                    <MDBox>
                      <MDTypography variant="h5">Reviews</MDTypography>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >31+ reviews</MDTypography>
                          <MDInput name="total" value={data?.review?.total} onChange={(e) => changeValue(e, 'review')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >4.0 star rating</MDTypography>
                          <MDInput name="average" value={data?.review?.average} onChange={(e) => changeValue(e, 'review')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >100% review responses</MDTypography>
                          <MDInput name="response" value={data?.review?.response} onChange={(e) => changeValue(e, 'review')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >New review(s) within 30 days</MDTypography>
                          <MDInput name="newResponse" value={data?.review?.newResponse} onChange={(e) => changeValue(e, 'review')} fullWidth />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                  <Grid item lg={12} sx={{ mt: 4 }} >
                    <MDBox>
                      <MDTypography variant="h5">Products and Services</MDTypography>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Product listings present</MDTypography>
                          <MDInput name="product" value={data?.product.product} onChange={(e) => changeValue(e, 'product')} fullWidth />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                  <Grid item lg={12} sx={{ mt: 4 }} >
                    <MDBox>
                      <MDTypography variant="h5">Q&A</MDTypography>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >10+ questions asked</MDTypography>
                          <MDInput name="asked" value={data?.qa.asked} onChange={(e) => changeValue(e, 'qa')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >100% questions answered</MDTypography>
                          <MDInput name="answered" value={data?.qa.answered} onChange={(e) => changeValue(e, 'qa')} fullWidth />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                  <Grid item lg={12} sx={{ mt: 4 }} >
                    <MDBox>
                      <MDTypography variant="h5">Posts</MDTypography>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Recent post within the past 30 days</MDTypography>
                          <MDInput name="recent" value={data?.post.recent} onChange={(e) => changeValue(e, 'post')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Tracking implemented</MDTypography>
                          <MDInput name="tracking" value={data?.post.tracking} onChange={(e) => changeValue(e, 'post')} fullWidth />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                  <Grid item lg={12} sx={{ mt: 4 }} >
                    <MDBox>
                      <MDTypography variant="h5">Benchmarking</MDTypography>
                      <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <MDBox sx={{ pt: 2, width: '50%', px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Focus keyword in top 3</MDTypography>
                          <MDInput name="focus_keyword" value={data?.benchmarking.focus_keyword} onChange={(e) => changeValue(e, 'benchmarking')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Secondary keywords in top 10</MDTypography>
                          <MDInput name="second_keyword" value={data?.benchmarking.second_keyword} onChange={(e) => changeValue(e, 'benchmarking')} fullWidth />
                        </MDBox>
                        <MDBox sx={{ pt: 2, width: '50%',  px: 1 }} >
                          <MDTypography sx={{ fontSize: '14px', color: 'light-gray'}} >Google Analytics implemented</MDTypography>
                          <MDInput name="anlytics" value={data?.benchmarking.anlytics} onChange={(e) => changeValue(e, 'benchmarking')} fullWidth />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Grid>
                </Grid>
              </div>
              <Grid sx={{mt: 4, mb: 6, textAlign: 'center' }}>
                <MDButton sx={{ width: '250px' }} variant="gradient" color="info" onClick={openInfoSB} >
                  Download PDF
                </MDButton>
                <MDButton sx={{ width: '250px', ml: 2 }} variant="gradient" color="info" onClick={() => sendPDFToUsers()} >
                  Submit
                </MDButton>
              </Grid>
              {renderInfoSB}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <DetailModal 
        openModal={openDetailModal}
        closeModal={() => closeModal()}
        sendData={(data) => {generatePDFToUsers(data)}}
      />
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default GraderExternal;
